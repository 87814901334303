import { EventEmitter, Injectable } from '@angular/core';
import { Tenant } from '../../store/reviewer/reviewer.interface';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class EmittersService {
  tenantEmitter:EventEmitter<Tenant> = new EventEmitter();

  skillAndExperienceEmitter: EventEmitter<any> = new EventEmitter();
  credentialEmitter: EventEmitter<any> = new EventEmitter();
  myProjectEmitter: EventEmitter<boolean> = new EventEmitter();
  reviewerListEmitter: EventEmitter<void> = new EventEmitter();


  constructor(private storageService:StorageService) {
    this.tenantEmitter.subscribe((event)=>{
        this.storageService.setTenant(event);
    });
   }
}
